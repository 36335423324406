<template>
  <div>
    <c-card title="서명" height="520px">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            color="purple"
            label="초기화"
            icon="autorenew"
            name="col1"
            @btnClicked="undo">
          </c-btn>
          <c-btn label="저장" icon="save" @btnClicked="save" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-12">
          <VueSignaturePad width="100%" height="500px" ref="signaturePad" id="signature"/>
        </div>
      </template>
    </c-card>
  </div>
</template>

<script>
export default {
  name: 'swp-signature',
  props: {
  },
  computed: {
  },
  watch: {
  },
  data() {
    return {
      options: {
        penColor: '#00f',
        minWidth: 3,
        maxWidth: 5,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      if (isEmpty) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '서명을 해주세요.',
          type: 'warning',
        });
      } else {
        this.closePopup(data);
      }
    },
    closePopup(data) {
      this.$emit('closePopup', data);
    },
  }
};
</script>

<style>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>